"use client";
import React, { useState, useEffect } from "react";
import { Sidebar } from "..";
import Topbar from "../Topbar";
import Footer from "../Footer";
import Header from "../Header";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getNavMenuData } from "../../app/apis/getApis";
import ReservationOffcanvas from "../ReservationOffcanvas";
import { usePathname } from "next/navigation";
import WishlistOffcanvas from "../WishlistOffcanvas";
import VerifyEmail from "../VerifyEmail";
import { useUserStore } from "../../store/User";

const Layout = ({ children }: any) => {
  const [isMounted, setIsMounted] = useState(false);
  const { user } = useUserStore((state: any) => state)

  const pathname = usePathname();

  const [showHomeNavbar, setShowHomeNavbar] = useState(true);

  useEffect(() => {
    let handleScroll: any;
    if (typeof document !== "undefined") {
      handleScroll = () => {
        const heroBanner = document.getElementById("heroBanner");
        if (heroBanner) {
          const offset = heroBanner.offsetHeight;
          setShowHomeNavbar(window.scrollY <= offset);
        }
      };

      document.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (typeof document !== "undefined") {
        document.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);


  const [isSandwichOpen, setisSandwichOpen] = useState(false);

  const navDataQuery = useQuery({
    queryKey: ["navMenuData"],
    queryFn: getNavMenuData,
  });

  const sandwichTriger = () => {
    setisSandwichOpen((prev) => !prev);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);
  if (!isMounted) {
    return null;
  }


  return (
    <>
      {pathname.includes("login") || pathname.includes("signup") ? (
        <></>
      ) : (
        <>
          <Sidebar setisSandwichOpen={setisSandwichOpen} isSandwichOpen={isSandwichOpen} navData={navDataQuery?.data?.data.body.data} />
          {user ? ((Boolean(user?.is_email_verified)) || (Boolean(user?.is_phone_number_verified))) ? "" : <VerifyEmail /> : ""}
          <Topbar />
          <Header sandwichTriger={sandwichTriger} navData={navDataQuery?.data?.data.body.data} showClassicNav={showHomeNavbar} />
        </>
      )}
      <>{children}</>
      {pathname.includes("login") || pathname.includes("signup") ? (
        <></>
      ) : (
        <>
          <Footer />
        </>
      )}
    </>
  );
};

export default Layout;
